<template>
  <div
    v-if="items && items.length > 0"
    class="col-6"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapseUnpaidLeaves`"
        class="card-header"
      >
        <h3 class="float-start">
          <a data-action="collapse"><i
            data-feather="life-buoy"
            class="w-icon-24"
          /> Unpaid leaves <span
            v-if="!loading"
            class="badge bg-light-info"
          >{{ total }}</span></a>
        </h3>
        <div class="heading-elements">
          <ul class="list-inline mb-0 disp-in ms-2">
            <li>
              <a data-action="collapse"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              ><polyline points="6 9 12 15 18 9" /></svg></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseUnpaidLeaves`"
        :visible="fields.find(e => e.name == 'Unpaid leaves').visibility"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th
                    v-if="loggedUser.roles.includes('super-admin')"
                  >Name</th>
                  <th>Begin date</th>
                  <th>End date</th>
                  <th>File</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td v-if="loggedUser.roles.includes('super-admin')">
                    {{ item.user ? item.user.name : '--' }}
                  </td>
                  <td>{{ item.begin_date ? item.begin_date : '--' }}</td>
                  <td>{{ item.end_date ? item.end_date : '--' }}</td>
                  <td>
                    <a
                      :href="item.files_ && item.files_[0] ? item.files_[0].url : '-'"
                      target="_blank"
                      title="view document"
                      class="btn bg-light-info btn-sm"
                    ><i data-feather="file" /></a>
                  </td>
                  <td class="text-end">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <i data-feather="more-vertical" />
                      </template>
                      <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                        <b-dropdown-item
                          target="_blank"
                          :href="$router.resolve({name: 'researcher.administration.unpaid-leaves.edit', params: { id: item.id }}).href"
                        >
                          <i
                            class="me-50"
                            data-feather="eye"
                          /> View
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-1">
            <Paginator
              :total="total"
              :store="'unpaidLeaves/filter'"
              :aux-filters="{ active: true }"
              @loading="setLoading"
            />
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Paginator from '@/views/back/partials/Paginator.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    Paginator,
    BCollapse,
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      items: 'unpaidLeaves/items',
      total: 'unpaidLeaves/itemsTotal',
      loggedUser: 'auth/admin',
      user: 'users/user',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    this.reloadPage()
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
    async reloadPage() {
      this.loading = true
      await this.$store.dispatch('unpaidLeaves/filter', { active: true })
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>
